// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-kontakt-sukces-js": () => import("./../src/pages/kontakt/sukces.js" /* webpackChunkName: "component---src-pages-kontakt-sukces-js" */),
  "component---src-pages-oferta-aplikacje-mobilne-js": () => import("./../src/pages/oferta/aplikacje-mobilne.js" /* webpackChunkName: "component---src-pages-oferta-aplikacje-mobilne-js" */),
  "component---src-pages-oferta-inne-uslugi-js": () => import("./../src/pages/oferta/inne-uslugi.js" /* webpackChunkName: "component---src-pages-oferta-inne-uslugi-js" */),
  "component---src-pages-oferta-strony-www-js": () => import("./../src/pages/oferta/strony-www.js" /* webpackChunkName: "component---src-pages-oferta-strony-www-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

