import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import MessengerCustomerChat from 'react-messenger-customer-chat'

import Transition from 'components/Transition'

const Layout = ({ location, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
            image
            description
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet titleTemplate={`${ data.site.siteMetadata.title } - %s`}>
          <html lang="pl" />
          <meta
            name="keywords"
            content="projektowanie stron www, aplikacje www, kompleksowe tworzenie oprogramowania, aplikacje mobilne, aplikacje android, aplikacje ios, chmura, karer media"
          />
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta
            property="og:description"
            content={data.site.siteMetadata.description}
          />
          <meta property="og:image" content={data.site.siteMetadata.image} />
        </Helmet>
        <Transition location={location}>{children}</Transition>
        <MessengerCustomerChat
          pageId="134071017267540"
          appId="264968754170383"
          themeColor="#037f70"
          language="pl_PL"
          loggedInGreeting="Cześć! Jak możemy Ci pomóc?"
          loggedOutGreeting="Cześć! Jak możemy Ci pomóc?"
        />
      </Fragment>
    )}
  />
)

export default Layout
